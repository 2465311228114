import React from "react"

import Layout from "../components/layout"
import Topicpath from "../components/topicpath"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons"

import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export const query = graphql`
  query {
    about:file(relativePath: {eq: "about.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
        original {
          src
          height
          width
        }
      }
    }
    site {
      siteMetadata {
        description
      }
    }
  }
`

export default ({ location, data }) => {

  return <div>
    <SEO
      pagetitle="このサイトについて"
      pagepath={location.pathname}
      image={data.about.childImageSharp.original.src}
      imageW={data.about.childImageSharp.original.width}
      imageH={data.about.childImageSharp.original.height}
    />
    <Layout>
      <div className="eyecatch">
        <figure>
          <Img fluid={data.about.childImageSharp.fluid} style={{ height: "100%" }} alt="ブルーベリー＆ヨーグルト" />
        </figure>
        <h1 className="bar">このサイトについて</h1>
      </div>
      <Topicpath pageUrl={location.pathname} />
      <article className="content">
        <div className="container">

          <div className="lowerbody">
            <h2><FontAwesomeIcon icon={faCheckSquare} />このサイトは何か。</h2>
            <p>
              このサイトは、将来的に私自らデザインした服を売るファッションブランド（Henri Morhange）を立ち上げ軌道に乗せる為の、手段の一つとして運営しています。<br />
              服好き歴≒年齢な私が、ファッションに関しての全知識とふと思った事を整理して、公開する予定です。<br />
            </p>

            <h2><FontAwesomeIcon icon={faCheckSquare} />このサイトを始めたきっかけ。</h2>
            <p>
              多事多難によって夢追うどころではなく何もかもを諦め、ファッションとは全く無関係な職に就き、薄給を僅かでも上げようと１０年弱日々邁進してきました。<br />
              けれど、その仕事がそれ程得意でも好きでも無く、不況やAIやDXの波によって「この職種では一生食っていける程の才能が無い」と悟り、やっぱり大好きなファッションで生活費を稼ぎたくて、始めました。
            </p>

            <h2><FontAwesomeIcon icon={faCheckSquare} />ロゴに込めた想い</h2>
            <p>
              ロゴ部分（Henri Morhange）の読みは、アンリ・モランジュです。まぁまぁ急拵えで考えてこれしか思い浮かばなかったので、無断で使用したり商標登録されたり等されると大変困ります。<br />
              この件については、温情を施して頂ける事を祈ります。
            </p>

            <h2><FontAwesomeIcon icon={faCheckSquare} />ブランドイメージ</h2>
            <p>
              日本のファッションは、着る人に冷た過ぎる。ファッションでもっと着る人を大切に。<br />
              美の基準とは、一人一人の生まれと人生の中にある。<br /><br />

              マネキンのように容姿端麗などこかの誰かが着る服ではなく、生身の普通の人間を肯定する服。<br />
              偽らないネガティブな感情も素直に表す、素顔を最も引き立てる服。偏見に冷遇される無意識を開放する服。女らしさ・ルッキズム・エイジズムに囚われない服。<br />
              熱い時、寒い時、疲れた時、着たくなる。走る事もできる。着る人本位な優しい服。<br />
              飽きさせない奥深さと新鮮な楽しさ。<br />
              着る人の為、自分の為、美意識と向き合う事。<br />
              を提供することを目指しています。
            </p>
            <h2><FontAwesomeIcon icon={faCheckSquare} />ブランドの道筋</h2>
            <p>
              私の思い描く最終的なブランドイメージは、時代を一歩先に行っていてあまり評価されない・解りにくい・売れないと予想されるので、その前に、半歩だけ先を行くものを中心に世に出して、下積みを重ねようと思っています。<br /><br />

              おしゃれの定義）<br />
              外見が美人、くびれ、若さ、女性らしさ、色気、重視<br />
              ↓<br />
              そうではない外見の良さを啓蒙する。着心地を重視する。【半歩先】<br />
              古い価値観の人にも、解りやすく、真似しやすく、覚えやすく、流行らせる為、外見に似合う事を重視する時期。<br />
              ↓<br />
              内面の表現についても本格的に啓蒙する。【一歩先】

            </p>
            <h2><FontAwesomeIcon icon={faCheckSquare} />サイトの仕様</h2>
            <p>
              静的サイトジェネレーターGatsby＋contentful＋Netlifyを使用しサイトを自作しました。<br />
              (※<OutboundLink href={`https://www.gatsbyjs.com/`}>Gatsby</OutboundLink>とは。※<OutboundLink href={`https://www.contentful.com/`}>contentful</OutboundLink>とは。※<OutboundLink href={`https://www.netlify.com/`}>Netlify</OutboundLink>とは。)<br />
              なぜWordPressやwix、note、はてなブログ等を頼らなかったかというと、Gatsby勉強した方が自己投資になる+自由度が高い+Reactってちょっと好きかもという理由です。
            </p>
            <h2><FontAwesomeIcon icon={faCheckSquare} />詳しい仕様</h2>
            <p>以下のような仕様で、公開しています。</p>
            <ul>
              <li>Gatsby、Netlify、contentful、使用</li>
              <li>レスポンシブ対応</li>
              <li>IE１１対応</li>
              <li>RSS対応</li>
              <li>google analytics設置</li>
              <li>OGP対応</li>
              <li>PWA対応</li>
              <li>画像軽量化、表示速度高速化対応</li>
            </ul>
            <h2><FontAwesomeIcon icon={faCheckSquare} />注意</h2>
            <p>勉強用、試作用に試し書きする為バグ発生する可能性があります。</p>
            <h2><FontAwesomeIcon icon={faCheckSquare} />参考</h2>
            <p>このサイトは、エビスコム著　「Webサイト高速化のための　静的サイトジェネレーター活用入門」を参考に作りました。</p>
            <h2><FontAwesomeIcon icon={faCheckSquare} />ファッションに興味を持ったきっかけ</h2>
            <p>
              物心ついた頃（２～３歳頃？）、近所の商業施設のショーウィンドウ越しに始めてウェディングドレスを見た瞬間、『ほぼ白のみでこのような美しいものが作れるなんて、凄い。この形を考えた人は凄い。』と感動し衝撃を受けた。そして『これを作りたい。』と思った。<br />
              その衝撃以来、服の形の虜になる。それから少し月日が経ち、幼稚園児時代に『服を作る仕事が存在する』という事を知り、それが『ファッションデザイナー』と呼ばれている事を知る。今思うと、少し変わった子供だった。<br />
            </p>
          </div>
        </div>
      </article>

    </Layout>
  </div>
}
